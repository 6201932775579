import { DEFAULT_ADS_NUMBER_PER_PAGE, PER_PAGE_OPTIONS } from '@domains/shared/consts/defaultPerPage';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { PageChangeEvent } from '@nexus/lib-react/dist/core/Pagination';
import type { DropdownOption } from '@type/search/dropdownOption';
import { checkIsOption } from '@type/search/dropdownOption';
import { useRouter } from 'next/router';
import type { JSX } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { MultiValue, SingleValue } from 'react-select';
import { useDebounce } from 'react-use';

import {
    Container,
    PageSizeContainer,
    PageSizeDropdown,
    PageSizeLabel,
    StyledPagination,
} from './BasePagination.theme';

const convertValueToOption = (value: string): DropdownOption => ({ label: value, value });

type LimitOption = MultiValue<DropdownOption> | SingleValue<DropdownOption>;

interface Props {
    numberOfPages?: number;
}

export const BasePagination = ({ numberOfPages }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const router = useRouter();

    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [page, setPage] = useState<number>(router.query.page ? +router.query.page : 1);
    const baseValueRef = useRef(page);
    const [limit, setLimit] = useState<LimitOption>(() =>
        convertValueToOption(router.query.limit?.toString() ?? DEFAULT_ADS_NUMBER_PER_PAGE),
    );

    const defaultPerPage = convertValueToOption(DEFAULT_ADS_NUMBER_PER_PAGE);

    const handlePageChange = async (): Promise<void> => {
        setIsDisabled((state) => !state);
        baseValueRef.current = page;

        if (page === 1) {
            delete router.query.page;
            await router.push({ query: router.query });
        } else {
            await router.push({
                query: { ...router.query, page: page },
            });
        }
        setIsDisabled((state) => !state);
    };

    const handlePaginationOnChange = async (event: PageChangeEvent): Promise<void> => {
        const pageParam = router.query?.page;
        if (page !== event.page && pageParam !== event.page.toString()) {
            setPage(event.page);
        }
    };

    useDebounce(
        async () => {
            // case 1: don't route to same page twice
            // case 2: ignore page 0 as it's handled in other useEffect
            if (page === baseValueRef.current || router.query?.page === '0') {
                return;
            }

            await handlePageChange();
        },
        1000,
        [page],
    );

    const handleOnPageSizeChange = async (option: LimitOption): Promise<void> => {
        if (!checkIsOption(option)) {
            return;
        }

        setLimit(option);
        if (router.query?.limit !== option.value) {
            delete router.query.page;
            await router.push({
                href: router.route,
                query: { ...router.query, limit: option.value },
            });
        }
    };

    const trackOnMenuOpen = useCallback(() => {
        trackEvent('nb_ads_click');
    }, [trackEvent]);

    const trackOnMenuSelect = useCallback(
        (option: DropdownOption | null): void => {
            if (!checkIsOption(option)) {
                return;
            }

            trackEvent('nb_ads_selected', { nb_ads: option.value });
        },
        [trackEvent],
    );

    useEffect(() => {
        if (router.query?.page === '0') {
            // case 1: when user input 0 to query
            setPage(1);
            delete router.query.page;
            router.push({ query: router.query });
        } else if (!router.query?.page && baseValueRef.current !== 1) {
            // case 1: when user click "submit" on search page (filters)
            // case 2: update "page" value outside pagination and without updating url
            setPage(1);
        }
    }, [page, router, router.query.page]);

    return (
        <Container>
            {numberOfPages ? (
                <StyledPagination
                    count={numberOfPages}
                    page={page}
                    onChange={handlePaginationOnChange}
                    disabled={isDisabled}
                    dataAttributes={{
                        cy: 'frontend.search.base-pagination.nexus-pagination',
                    }}
                />
            ) : null}
            <PageSizeContainer>
                <PageSizeLabel>{t('frontend.search.pagination.perPage')}</PageSizeLabel>
                <PageSizeDropdown
                    name="entriesPerPage"
                    defaultValue={defaultPerPage}
                    value={limit}
                    options={PER_PAGE_OPTIONS.map(convertValueToOption)}
                    shouldTranslateLabels={false}
                    onChange={handleOnPageSizeChange}
                    trackOnMenuOpen={trackOnMenuOpen}
                    trackOnSelect={trackOnMenuSelect}
                    aria-label={t('frontend.search.pagination.perPage')}
                    instanceId="entriesPerPage"
                    variant="primary"
                    isSearchable={false}
                />
            </PageSizeContainer>
        </Container>
    );
};

export default BasePagination;
